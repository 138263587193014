import { StockItem } from '../stores';

export const stockSliderAmounts = ({
  target,
  value,
  sharePrice,
}: StockItem) => {
  const left = target - value;
  const amount = left / sharePrice / 4;

  const roundTo = amount > 5 ? 1 : amount < 0.4 ? 100 : 4;

  const max2Decimals = (n: number) => Math.round(n * roundTo) / roundTo;

  return [amount, amount * 2, amount * 3].map((n) => max2Decimals(n));
};
