import { mockItems, mockRegistry } from 'utils/mockData';
import { makeAutoObservable } from 'mobx';

type Image = { uri: string };

export type StockItem = {
  type: string;
  value: number;
  displayName: string;
  target: number;
  sharePrice: number;
  image?: Image;
};

type Contributor = { name: string; amount: number };

type Registry = {
  current: number;
  target: number;
  contributors?: Contributor[];
};

class RegistryStore {
  registry: Registry;
  items: StockItem[];

  constructor() {
    makeAutoObservable(this);
    this.registry = mockRegistry;
    this.items = mockItems;
  }

  getRegistry() {
    return this.registry;
  }

  getItems() {
    return this.items;
  }
}

export default new RegistryStore();
