import { StrictMode } from 'react';
import ReactDOM from 'react-dom';
import App from './app';
import LogRocket from 'logrocket';
import SnapchatPixel from 'react-snapchat-pixel';
import FacebookPixel from 'react-facebook-pixel';

if (process.env.NODE_ENV === 'production') {
  LogRocket.init('9giet1/comp');
  FacebookPixel.init('3208171019508509');
  SnapchatPixel.init('b01c855f-9db9-4e5d-b3dd-69de0e816dad');
}

ReactDOM.render(
  <StrictMode>
    <App />
  </StrictMode>,
  document.getElementById('root')
);
