import { makeAutoObservable } from 'mobx';
import { StockItem } from '.';

type CartItem = { amount: number };
type Cart = Record<string, CartItem>;

class CartStore {
  cart: Cart = {};

  constructor() {
    makeAutoObservable(this);
  }

  getCart() {
    return this.cart;
  }

  addToCart({ type, amount }: StockItem & { amount: number }) {
    this.cart[type] = { amount };
  }
}

export default new CartStore();
