import { FC, ReactElement } from 'react';
import { Box, Text, Flex, useColorModeValue } from '@chakra-ui/react';
import styled from '@emotion/styled';
import logo from 'media/logo.svg';

const Logo = styled.img`
  width: 80px;
  height: 40px;
  object-fit: contain;
`;

const LogoBox = styled.div`
  background-color: white;
  aspect-ratio: 1;
  border-radius: 100px;
  display: flex;
  align-items: center;
`;

type IProps = { position?: string; disclaimer?: string | ReactElement };

const Footer: FC<IProps> = ({ position = 'absolute', disclaimer }) => (
  <Box
    position={position as any}
    bottom="0"
    left="0"
    mt={3}
    py={6}
    width="100vw"
    bg={useColorModeValue('gray.50', 'gray.900')}
    color={useColorModeValue('gray.700', 'gray.200')}
  >
    <Flex align="center" justify="center">
      <LogoBox>
        <Logo src={logo} />
      </LogoBox>
    </Flex>
    <Text pt={2} fontSize="sm" textAlign="center">
      © 2022 Comp Financial. All rights reserved
    </Text>
    <Text fontSize="xs" textAlign="center">
      {disclaimer}
    </Text>
  </Box>
);

export default Footer;
