import {
  Box,
  Heading,
  Container,
  Text,
  Stack,
  Image,
  useColorModeValue,
  useBreakpointValue,
  Link,
  SimpleGrid,
  Flex,
} from '@chakra-ui/react';
import demoImg from 'media/product-img.webp';
import arrow from 'media/arrow.svg';
import { WaitlistModal } from './modal';
import styled from '@emotion/styled';

const MainImage = styled.img`
  width: 300px;
  height: 600px;
  object-fit: contain;
`;

export const Cta = () => {
  const showArrow = useBreakpointValue({ base: 'none', md: 'inherit' });

  return (
    <>
      <Container maxW="5xl" marginY={3}>
        <SimpleGrid columns={{ base: 1, md: 2 }} spacing={4}>
          <Flex
            align="center"
            justify="center"
            order={1}
            pb={{ base: 6, md: 0 }}
          >
            <MainImage src={demoImg} />
          </Flex>
          <Stack
            as={Box}
            textAlign="center"
            spacing={{ base: 6, md: 8 }}
            py={{ base: 14, md: 36 }}
            marginX={5}
          >
            <Heading
              fontWeight={600}
              fontSize={{ base: '4xl', md: '6xl' }}
              lineHeight="110%"
            >
              Join the <br />
              <Text as="span" color="green.400">
                future of finance
              </Text>
            </Heading>
            <Text color="gray.500">
              Open up a gift registry of stocks and allow friends and family to
              contribute. Join the waitlist now and we&apos;ll fund your account
              with Comp stock.
            </Text>
            <Stack
              direction="column"
              spacing={3}
              align="center"
              alignSelf="center"
              position="relative"
            >
              <WaitlistModal buttonProps={{ px: 6, mr: 2 }} />
              <Link href="#features" colorScheme="blue" size="sm">
                Learn more
              </Link>
              <Box display={showArrow}>
                <Image
                  src={arrow}
                  color={useColorModeValue('gray.800', 'gray.300')}
                  w={71}
                  position="absolute"
                  right={-73}
                  top="2.5px"
                  transform="rotate(10deg)"
                />
                <Text
                  fontSize="md"
                  position="absolute"
                  right="-130px"
                  top="-15px"
                  transform="rotate(12deg)"
                >
                  Commission Free
                </Text>
              </Box>
            </Stack>
          </Stack>
        </SimpleGrid>
      </Container>
    </>
  );
};
