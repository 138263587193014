import { FC } from 'react';
import { Flex, ListItem, Image, Box, useToken } from '@chakra-ui/react';
import { observer } from 'mobx-react-lite';
import { cartStore, StockItem } from 'stores';
import styled from '@emotion/styled';
import { formatCurrency } from 'utils/formatCurrency';

const StyledItem = styled(ListItem)`
  padding: 4px;
  margin: 8px;
`;

const StyledBox = styled(Box)`
  border-radius: 20px;
  border: 3.5px solid ${(props) => props.borderColor};
  margin-right: 6px;
`;

type IProps = { item: StockItem };

const CartItem: FC<IProps> = observer(({ item }) => {
  const cart = cartStore.getCart();

  const { type, sharePrice, image } = item;
  const { amount } = cart[type] || {};

  if (!cart[type] || cart[type].amount === 0) return <></>;

  return (
    <StyledItem>
      <Flex align="center">
        <StyledBox borderColor={useToken('colors', 'green.100')}>
          <Image
            src={image?.uri}
            boxSize="40px"
            padding={1}
            objectFit="contain"
          />
        </StyledBox>
        {amount.toFixed(2)} share
        {amount !== 1 ? 's' : ''} - {formatCurrency(amount * sharePrice)}
      </Flex>
    </StyledItem>
  );
});

export default CartItem;
