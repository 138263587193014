import tabStore from 'stores/tabStore';
import { Text, Button, Flex, Heading } from '@chakra-ui/react';
import { Marquee, Footer } from 'components/invest';

//<CurrentPie />

const TabOne = () => {
  return (
    <>
      <Flex
        justify="center"
        align="center"
        direction="column"
        minHeight="50vh"
        height="-webkit-fill-available"
      >
        <Marquee />
        <Heading textAlign="center">Jimmy&apos;s Baby Registry</Heading>
        <Text align="center" fontSize="lg">
          You&apos;ve been invited to contribute to Jimmy&apos;s Baby Registry.
          <br />
          Click continue to select your gift.
        </Text>
        <Button m={2} onClick={() => tabStore.next()}>
          Continue
        </Button>
      </Flex>
      <Footer />
    </>
  );
};

export default TabOne;
