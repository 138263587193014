import { List, ListIcon, Text, ListItem } from '@chakra-ui/react';
import { CheckIcon } from '@chakra-ui/icons';
import { FC } from 'react';
import styled from '@emotion/styled';

const ListSubText = ({ children }: { children: string }) => (
  <Text color="gray.500" fontSize="xs" ml="24px">
    {children}
  </Text>
);

const OneLine = styled.span`
  white-space: nowrap;
`;

export const Info: FC = () => {
  return (
    <List spacing={3}>
      <ListItem>
        <OneLine>
          <ListIcon as={CheckIcon} color="green.500" />
          We&apos;ll add $19 in Comp stock to your account
        </OneLine>
        <ListSubText>
          Become a part owner and benefit from our growth.
        </ListSubText>
      </ListItem>
      <ListItem>
        <ListIcon as={CheckIcon} color="green.500" />
        Enjoy commission-free trading for life
        <ListSubText>Pay $0 in fees for buying and selling stock.</ListSubText>
      </ListItem>
      <ListItem>
        <ListIcon as={CheckIcon} color="green.500" />
        Access to financial tools
        <ListSubText>
          Improve your financial literacy with our tools.
        </ListSubText>
      </ListItem>
    </List>
  );
};
