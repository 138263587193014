import { Link } from '@chakra-ui/react';

export const Disclaimer = () => (
  <>
    Investing in stocks can be volatile and involves risk, including loss of
    principal. Past performance is not an indicator of future outcomes. S&#38;P
    500 returns are cumulative returns from 1926 according to&nbsp;
    <Link
      isExternal
      rel="nofollow"
      color="blue.500"
      href="https://www.investopedia.com/ask/answers/042415/what-average-annual-return-sp-500.asp#citation-4"
    >
      Investopedia
    </Link>
    .
  </>
);
