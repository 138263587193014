import {
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription,
} from '@chakra-ui/react';
import styled from '@emotion/styled';
import logo from 'media/logo-black.png';
import { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import SnapchatPixel from 'react-snapchat-pixel';
import FacebookPixel from 'react-facebook-pixel';

const Logo = styled.img`
  position: absolute;
  bottom: 10vh;
  width: 80px;
  height: 80px;
  object-fit: contain;
`;

const Success = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    if (searchParams.get('success')) {
      SnapchatPixel.track('PURCHASE');
      FacebookPixel.track('Purchase');
      setSearchParams({});
    }
  }, [searchParams]);

  return (
    <>
      <Alert
        status="success"
        variant="subtle"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        textAlign="center"
        minHeight="100vh"
        pb={20}
      >
        <Logo src={logo} />
        <AlertIcon boxSize="40px" mr={0} />
        <AlertTitle mt={4} mb={1} fontSize="lg">
          Thank you for joining our waitlist!
        </AlertTitle>
        <AlertDescription maxWidth="sm">
          We have added you to the waitlist queue and will let you know as soon
          as you can open an account.
        </AlertDescription>
      </Alert>
    </>
  );
};

export default Success;
