import { useState, useMemo, FC } from 'react';
import { AddIcon } from '@chakra-ui/icons';
import { cartStore, StockItem } from 'stores';
import { formatCurrency } from 'utils/formatCurrency';
import { stockSliderAmounts } from 'utils/stockSliderAmounts';
import {
  Flex,
  Slider,
  SliderTrack,
  SliderFilledTrack,
  SliderThumb,
  SliderMark,
  Modal,
  Text,
  ModalContent,
  IconButton,
  ModalBody,
  ModalOverlay,
  useDisclosure,
  ModalHeader,
  Button,
  Stat,
  StatLabel,
  StatNumber,
} from '@chakra-ui/react';

type IProps = {
  stock: StockItem;
  openCartDrawer: () => void;
};

const SelectStockModal: FC<IProps> = ({ stock, openCartDrawer }) => {
  const { isOpen, onClose, onOpen } = useDisclosure();
  const [amount, setAmount] = useState(0);

  const { displayName, target, value, sharePrice, type } = stock;
  const left = target - value;

  const [mark1, mark2, mark3] = useMemo(
    () => stockSliderAmounts(stock),
    [stock]
  );

  const handleAdd = () => {
    cartStore.addToCart({ ...stock, amount: amount / 100 });
    onClose();
    openCartDrawer();
  };

  return (
    <>
      <IconButton
        marginTop={1}
        width="full"
        onClick={onOpen}
        aria-label="add"
        size="sm"
        icon={<AddIcon />}
      />
      <Modal size="xs" isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            <Flex justify="space-between" align="center">
              <Text>{displayName}</Text>
              <Text fontSize="small" color="gray" marginTop={0.5}>
                {type} - {formatCurrency(sharePrice)}
              </Text>
            </Flex>
          </ModalHeader>
          <ModalBody>
            <Flex align="center" justify="center">
              <Stat>
                <StatLabel>Contributed</StatLabel>
                <StatNumber>{formatCurrency(value)}</StatNumber>
              </Stat>
              <Stat>
                <StatLabel>Target</StatLabel>
                <StatNumber>{formatCurrency(target)}</StatNumber>
              </Stat>
            </Flex>
            <Text marginBottom={8}>
              Choose how many shares you want to gift
            </Text>
            <Slider
              value={amount}
              max={(left / sharePrice) * 100}
              onChange={(v) => setAmount(v)}
            >
              <SliderMark value={mark1 * 100} mt="1" ml="-2.5" fontSize="sm">
                {mark1}
              </SliderMark>
              <SliderMark value={mark2 * 100} mt="1" ml="-2.5" fontSize="sm">
                {mark2}
              </SliderMark>
              <SliderMark value={mark3 * 100} mt="1" ml="-2.5" fontSize="sm">
                {mark3}
              </SliderMark>
              <SliderMark
                value={amount}
                textAlign="center"
                bg="blue.500"
                color="white"
                mt="-10"
                ml="-5"
                w="12"
              >
                {(amount / 100).toFixed(2)}
              </SliderMark>
              <SliderTrack>
                <SliderFilledTrack />
              </SliderTrack>
              <SliderThumb />
            </Slider>
          </ModalBody>
          <Flex justify="space-between" align="center" margin={6}>
            <Text fontWeight="bold">
              Total: {formatCurrency((amount / 100) * sharePrice)}
            </Text>
            <Button onClick={handleAdd}>Add</Button>
          </Flex>
        </ModalContent>
      </Modal>
    </>
  );
};

export default SelectStockModal;
