import { Box, useColorModeValue, Flex } from '@chakra-ui/react';
import { WaitlistModal } from './modal';
import logo from 'media/logo.svg';
import styled from '@emotion/styled';

const Logo = styled.img`
  height: 60px;
  width: 60px;
  object-fit: contain;
`;

export const Navigation = () => {
  return (
    <Box
      borderBottom={1}
      borderStyle="solid"
      borderColor={useColorModeValue('gray.200', 'gray.900')}
      py={3}
      width="100vw"
    >
      <Flex justify="space-between" align="center" width="80%" margin="auto">
        <Logo src={logo} />
        <WaitlistModal />
      </Flex>
    </Box>
  );
};
