import styled from '@emotion/styled';
import { registryStore } from 'stores';
import {
  Flex,
  Text,
  Box,
  Grid,
  GridItem,
  useDisclosure,
  useColorModeValue,
} from '@chakra-ui/react';
import CartDrawer from './cartDrawer';
import SelectStockModal from './selectStockModal';

const Image = styled.img`
  height: 90px;
  width: 90px;
  object-position: center center;
  object-fit: contain;
`;

const StockCards = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const items = registryStore.getItems();

  return (
    <>
      <CartDrawer isOpen={isOpen} onOpen={onOpen} onClose={onClose} />
      <Flex align="center" justify="center">
        <Grid
          w="full"
          maxW="540px"
          templateColumns="repeat(6, 1fr)"
          gap={4}
          alignItems="center"
        >
          {items.map((stock, key) => {
            const { image, type, displayName } = stock;
            return (
              <GridItem colSpan={{ base: 3, lg: 2 }} key={key}>
                <Box
                  maxW="180px"
                  w="full"
                  bg={useColorModeValue('white', 'gray.900')}
                  boxShadow="2xl"
                  rounded="lg"
                  p={6}
                  textAlign="center"
                >
                  <Flex align="center" justify="center">
                    <Image src={image?.uri} />
                  </Flex>
                  <Flex
                    justify="center"
                    align="center"
                    direction="column"
                    textAlign="center"
                  >
                    <Text align="left" size="large" fontWeight="bold">
                      {displayName}
                    </Text>
                    <Text align="left">{type}</Text>
                  </Flex>
                  <SelectStockModal stock={stock} openCartDrawer={onOpen} />
                </Box>
              </GridItem>
            );
          })}
        </Grid>
      </Flex>
    </>
  );
};

export default StockCards;
