import { Heading, Stack, Container, SimpleGrid, Text } from '@chakra-ui/react';
import stockMarketChart from 'media/stockMarketChart.svg';

export const InfoWithChart = () => {
  return (
    <Container maxW="5xl" marginY={20}>
      <SimpleGrid columns={{ base: 1, md: 2 }} spacing={10}>
        <Stack spacing={3}>
          <Heading size="xl" mt={4}>
            Get real, compound returns
          </Heading>
          <Text color="gray.500" fontSize="lg">
            The S&#38;P 500 has returned 10.49% yearly on average for the past
            100 years. By receiving stocks as gifts you and your family can
            enjoy the returns the stock market offers.
          </Text>
        </Stack>
        <img src={stockMarketChart} />
      </SimpleGrid>
    </Container>
  );
};
