import { BrowserRouter, Route, Routes as RouterRoutes } from 'react-router-dom';
import { Invest, Success, Waitlist } from './pages';

const Routes = () => (
  <BrowserRouter>
    <RouterRoutes>
      <Route path="/" element={<Waitlist />} />
      <Route path="/success" element={<Success />} />
      <Route path="/invest" element={<Invest />} />
    </RouterRoutes>
  </BrowserRouter>
);

export default Routes;
