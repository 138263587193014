import { makeAutoObservable } from 'mobx';

class TabStore {
  index = 0;

  constructor() {
    makeAutoObservable(this);
  }

  getIndex() {
    return this.index;
  }

  next() {
    this.index = this.index + 1;
  }

  prev() {
    this.index = this.index - 1;
  }
}

export default new TabStore();
