import Marquee from 'react-fast-marquee';
import { registryStore } from 'stores';
import {
  Text,
  Flex,
  StatArrow,
  useToken,
  useBreakpointValue,
} from '@chakra-ui/react';
import { IoCellular, IoStar } from 'react-icons/io5';
import { formatCurrency } from 'utils/formatCurrency';

const StockMarquee = () => {
  const width = useBreakpointValue({ base: '325px', md: '600px' });
  const gradientWidth = useBreakpointValue({ base: '100px', md: '200px' });
  const blue = useToken('colors', 'blue.500');

  const { contributors, target, current } = registryStore.getRegistry();
  const StyledText = (name: string, amount: number) => (
    <Text color="green" fontSize="lg">
      <Flex align="center" justify="center" gap={1}>
        <StatArrow type="increase" color="green" />
        {name} - {formatCurrency(amount, 0)}
      </Flex>
    </Text>
  );

  const TargetText = () => (
    <Text fontSize="lg" marginLeft={3}>
      <Flex align="center" justify="center" color="black" gap={1}>
        <IoCellular color={blue} />
        Target: {formatCurrency(target, 0)}
      </Flex>
    </Text>
  );

  const CurrentText = () => (
    <Text fontSize="lg" marginLeft={3}>
      <Flex align="center" justify="center" color="black" gap={1}>
        <IoStar color={blue} />
        Current: {formatCurrency(current, 0)}
      </Flex>
    </Text>
  );

  return (
    <>
      <Flex
        align="center"
        justify="center"
        position="absolute"
        top="50px"
        width={width}
      >
        <Marquee gradientWidth={gradientWidth} speed={40} pauseOnHover>
          <Flex gap={3} ml={3}>
            {contributors?.map(({ name, amount }) => StyledText(name, amount))}
          </Flex>
        </Marquee>
      </Flex>
      <Flex align="center" justify="center">
        <CurrentText />
        <TargetText />
      </Flex>
    </>
  );
};

export default StockMarquee;
