export const mockRegistry = {
  current: 1235,
  target: 3300,
  contributors: [
    { name: 'Grandma Sally', amount: 400 },
    { name: 'Grandpa Rick', amount: 100 },
    { name: 'John Rick', amount: 200 },
    { name: 'Kelly Rich', amount: 235 },
  ],
};

export const mockItems = [
  {
    type: 'AAPL',
    sharePrice: 163.47,
    displayName: 'Apple',
    value: 290,
    target: 700,
    image: {
      uri: 'https://upload.wikimedia.org/wikipedia/commons/f/fa/Apple_logo_black.svg',
    },
  },
  {
    type: 'FB',
    sharePrice: 199.73,
    displayName: 'Meta',
    value: 95,
    target: 500,
    image: {
      uri: 'https://upload.wikimedia.org/wikipedia/commons/thumb/b/b8/2021_Facebook_icon.svg/1200px-2021_Facebook_icon.svg.png',
    },
  },
  {
    type: 'AMZN',
    sharePrice: 2907.79,
    displayName: 'Amazon',
    value: 140,
    target: 500,
    image: {
      uri: 'https://upload.wikimedia.org/wikipedia/commons/thumb/a/a9/Amazon_logo.svg/603px-Amazon_logo.svg.png',
    },
  },
  {
    type: 'GOOG',
    sharePrice: 2634.07,
    displayName: 'Alphabet',
    value: 360,
    target: 1000,
    image: {
      uri: 'https://upload.wikimedia.org/wikipedia/commons/thumb/5/53/Google_%22G%22_Logo.svg/2048px-Google_%22G%22_Logo.svg.png',
    },
  },
  {
    type: 'TSLA',
    sharePrice: 841.46,
    displayName: 'Tesla',
    value: 350,
    target: 600,
    image: {
      uri: 'https://upload.wikimedia.org/wikipedia/commons/thumb/b/bd/Tesla_Motors.svg/793px-Tesla_Motors.svg.png',
    },
  },
];
