import { FC } from 'react';
import {
  ButtonProps,
  Button,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalFooter,
  ModalBody,
  Text,
  Flex,
  Link,
} from '@chakra-ui/react';
import SnapchatPixel from 'react-snapchat-pixel';
import FacebookPixel from 'react-facebook-pixel';
import { IoSend } from 'react-icons/io5';
import { Info } from './info';

type IProps = { buttonProps?: ButtonProps };

export const WaitlistModal: FC<IProps> = ({ buttonProps }) => {
  const { onOpen, onClose, isOpen } = useDisclosure();

  const trackClick = () => {
    SnapchatPixel.track('START_CHECKOUT');
    FacebookPixel.track('InitiateCheckout');
  };
  const url = 'https://buy.stripe.com/5kAdUk9XQ0EY4Ug8ww';

  return (
    <>
      <Button {...buttonProps} colorScheme="green" onClick={onOpen}>
        <Flex gap={2} align="center">
          <IoSend />
          Join the waitlist
        </Flex>
      </Button>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalCloseButton />
          <ModalHeader>Join now for $19</ModalHeader>
          <ModalBody>
            <Info />
          </ModalBody>
          <ModalFooter>
            <Flex align="center" gap={3}>
              <Text fontSize="9px" color="gray.500">
                Offering is made under Reg CF. Comp stock will be issued as a
                SAFE at time of registration, subject to Comp&apos;s terms of
                service.
              </Text>
              <Button
                as={Link}
                onClick={() => trackClick()}
                href={url}
                isExternal
                colorScheme="blue"
                textDecoration="none"
                paddingX={7}
              >
                Pay
              </Button>
            </Flex>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};
