import { StockCards } from 'components/invest';
import Intro from './intro';
import styled from '@emotion/styled';
import { tabStore } from 'stores';
import { observer } from 'mobx-react-lite';
import { Tabs, TabPanel, TabPanels, Button, Box } from '@chakra-ui/react';

const Container = styled(Box)`
  margin: auto;
  margin-top: 15px;
  padding: 15px;
`;

const Invest = observer(() => {
  return (
    <Container>
      <Tabs index={tabStore.index} backgroundColor="white">
        {tabStore.index > 0 && (
          <Button onClick={() => tabStore.prev()}>Back</Button>
        )}
        <TabPanels>
          <TabPanel>
            <Intro />
          </TabPanel>
          <TabPanel>
            <StockCards />
          </TabPanel>
        </TabPanels>
      </Tabs>
    </Container>
  );
});

export default Invest;
