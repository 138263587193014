import {
  Cta,
  Features,
  InfoWithChart,
  Navigation,
  Disclaimer,
} from 'components/waitlist';
import { Footer } from 'components/invest';

const Waitlist = () => {
  return (
    <>
      <Navigation />
      <Cta />
      <Features />
      <InfoWithChart />
      <Footer position="inherit" disclaimer={<Disclaimer />} />
    </>
  );
};

export default Waitlist;
