import Routes from './routes';
import { ChakraProvider } from '@chakra-ui/react';
import { theme } from './theme';
import './app.css';

const App = () => (
  <ChakraProvider theme={theme}>
    <Routes />
  </ChakraProvider>
);

export default App;
