import { FC } from 'react';
import {
  Flex,
  Drawer,
  DrawerBody,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  Button,
  List,
  IconButton,
  Icon,
} from '@chakra-ui/react';
import { MdShoppingCart } from 'react-icons/md';
import styled from '@emotion/styled';
import { CartItem } from '.';
import { registryStore } from 'stores';

const CartButton = styled(IconButton)`
  position: absolute;
  background-color: white;
  top: 10px;
  right: 10px;
`;

type IProps = { isOpen: boolean; onOpen: () => void; onClose: () => void };

const CartDrawer: FC<IProps> = ({ isOpen, onOpen, onClose }) => {
  const items = registryStore.getItems();

  return (
    <>
      <CartButton
        size="lg"
        icon={<Icon as={MdShoppingCart} />}
        aria-label="open"
        onClick={onOpen}
      />
      <Drawer isOpen={isOpen} placement="right" onClose={onClose}>
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerHeader>Your Cart</DrawerHeader>
          <DrawerBody>
            <List>
              {items.map((stock, key) => (
                <CartItem key={key} item={stock} />
              ))}
            </List>
            <Flex justify="right" direction="column" marginTop={3}>
              <Button onClick={onClose} colorScheme="green" variant="solid">
                Checkout
              </Button>
            </Flex>
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </>
  );
};

export default CartDrawer;
